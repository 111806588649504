<template>
  <BlokkliProvider
    v-if="homeConfig"
    v-bind="homeConfig.blokkliProps"
    edit-label="Edit stage"
    isolate
  >
    <BlokkliField
      name="field_paragraphs_stage"
      :list="homeConfig?.stageParagraphs"
      :class="containerClasses"
    />
  </BlokkliProvider>
  <div v-else :class="containerClasses">
    <ParagraphHomeStageTeaser
      v-for="(homeStageTeaser, index) in homeStageTeasers"
      v-bind="homeStageTeaser"
      :key="index"
    />
  </div>
</template>

<script lang="ts" setup>
import type { HomeStageTeaserProps } from '../Paragraph/HomeStageTeaser/index.vue'
import type { HomeConfigFragment } from '#graphql-operations'

defineProps<{
  homeConfig?: HomeConfigFragment
  homeStageTeasers?: HomeStageTeaserProps[]
}>()

const containerClasses =
  'md:layout-grid home-stage relative mx-auto max-w-screen-xxxl bg-grey-light-03 mobile-only:m-16 md:mt-24'
</script>

<style lang="scss" scoped>
/**
  * This is the easiest way to get the "border" effect on the stage.
  * The stage is full width and because we want to keep the grid of the stage
  * correct we can't set a margin or padding. By placing two white divs on the
  * left and right we can make it look like a margin.
  */
.home-stage,
:deep(.home-stage) {
  @screen md {
    &:before,
    &:after {
      content: '';
      @apply pointer-events-none absolute top-0 h-full w-32 bg-white;
    }

    &:before {
      @apply left-0;
    }
    &:after {
      @apply right-0;
    }
  }

  @screen xl {
    &:before,
    &:after {
      @apply hidden;
    }
  }
}
</style>
